<template>
    <v-container fluid>
        <v-card class="header-card">
            <v-card-title class="card-title">Team Applications</v-card-title>
        </v-card>
        <array-view
            :items="teamApplications"
            :headers="displayInfo"
            :total-pages="totalPages"
            :page="page"
            :size="size"
            :hide-actions="false"
            :deleteAction="true"
            @paginationChange="paginationChange"
            @onDelete="onDelete"
            @handleClick="handleClick"
        />
    </v-container>
</template>

<script>
    import teamApplicationService from '@/services/team-application'
    import ArrayView from '@/components/ArrayView'

    export default {
        name: 'Arena',
        components: { ArrayView },
        data: () => {
            return {
                successfullImport: null,
                teamApplications: [],
                displayInfo: [
                    {
                        text: 'Id',
                        value: 'id'
                    },
                    {
                        text: 'Team Name',
                        value: 'teamName'
                    },
                    {
                        text: 'Status',
                        value: 'status'
                    }
                ],
                showImportDialog: false,
                size: 5,
                totalPages: 0,
                page: 1
            }
        },
        created() {
            this.fetchAllTeamApplications()
        },
        methods: {
            paginationChange(page, size) {
                this.size = size
                this.page = page
                this.fetchAllTeamApplications()
            },
            fetchAllTeamApplications() {
                teamApplicationService.getTeamApplications(this.page, this.size).then(resp => {
                    this.teamApplications = resp.data.content
                    this.totalPages = resp.data.totalPages
                })
            },
            onDelete(application) {
                teamApplicationService.deleteTeamApplication(application.id).then(resp => {
                    this.fetchAllTeamApplications()
                })
            },
            handleClick(action, item) {
                this.$router.push(`/team-applications/${item.id}`)
            }
        }
    }
</script>

<style scoped>
.card-title {
    display: flex;
}

.v-list-item {
    min-height: 30px !important;
}

.v-card {
    min-width: 250px;
    position: relative;
}

.header-card {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1%;
}

.header-buttons {
    align-self: center;
}

.edit-button:hover {
    background-color: whitesmoke;
    color: white;
}

.screen-card {
    padding: 4%;
}

.overflow-text {
    max-width: 79%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.pagination {
    justify-content: center;
}
</style>
